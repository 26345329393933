<!--Vista para editar información que se muestra en el micrositio de la membresía-->
<template>
  <v-container fluid>
    <div>
      <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
        <v-row>
          <v-col cols="12" md="8" class="py-0">
            <h2 class="font-weight-bold">
              Vamos a construir tu micrositio de tu membresía
            </h2>
          </v-col>
          <v-col cols="12" md="4" class="py-0 d-flex flex-row justify-end">
            <v-btn
              @click="
                (affiliation.published = !affiliation.published),
                  _updateAffiliation()
              "
              :color="affiliation.published ? 'grey lighten-3' : 'secondary'"
              large
              rounded
              v-if="user.accountSetup == 'VALIDATED'"
              :disabled="
                !user.permissions.Membership ||
                !user.permissions.Membership.update
              "
            >
              <span class="font-weight-bold">{{
                affiliation.published
                  ? "No publicar membresía"
                  : "Publicar membresía"
              }}</span>
            </v-btn>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="grey lighten-3"
                  class="disabled"
                  large
                  rounded
                  v-if="user.accountSetup != 'VALIDATED'"
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                  {{
                    affiliation.published
                      ? "No publicar membresía"
                      : "Publicar membresía"
                  }}
                </v-btn>
              </template>
              <span
                >Por favor Integra tu cuenta bancaria antes de publicar tu
                membresia</span
              >
            </v-tooltip>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="pb-0">
                <p class="title font-weight-bold mb-1">
                  Banner y diseño de la membresía
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="py-0">
                <p>
                  Carga un banner de 1500x500 píxeles
                  <v-tooltip top max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Esta imagen se mostrará en la parte superior del
                      micrositio. Para tener una visualización adecuada se
                      recomienda que la imagen sea de 1500x500 pixeles.</span
                    >
                  </v-tooltip>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-img
                  v-if="affiliation.banner && affiliation.banner != ''"
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  :src="affiliation.banner"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="bannerDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
                <v-img
                  v-else
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  src="@/assets/images/dashboard/bannerPlaceholder.png"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="bannerDialog = true"
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="py-0">
                <p>
                  Carga un flyer de 800xN píxeles
                  <v-tooltip top max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Esta imagen se mostrará en el cuerpo del micrositio.
                      Normalmente se usa para mostrar información de la
                      membresía de una forma más gráfica. Para tener una
                      visualización adecuada se recomienda que la imagen sea de
                      800 pixeles de ancho y de alto puede ser cualquier
                      medida.</span
                    >
                  </v-tooltip>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" class="pt-0">
                <v-img
                  v-if="affiliation.flyer && affiliation.flyer != ''"
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  :src="affiliation.flyer"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="flyerDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
                <v-img
                  v-else
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  src="@/assets/images/dashboard/flyerPlaceholder.png"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="flyerDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
              </v-col>
              <v-col cols="12" sm="4">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="affiliation.name"
                      label="Dale un nombre a tu membresía"
                      filled
                      rounded
                      @blur="_updateAffiliation()"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="affiliation.emailContact"
                      label="Correo electrónico de contacto"
                      filled
                      rounded
                      @blur="_updateAffiliation()"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="affiliation.youtubeVideo"
                      label="Link de video promocional"
                      filled
                      rounded
                      @blur="_updateAffiliation()"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--
                <v-row>
                  <v-col>
                    <v-card class="align-content-center" min-height="150" max-height="200" :color="affiliation.micrositeConfiguration.primaryColor">
                      <div class="d-flex align-content-space-between flex-wrap" style="min-height: 150px; min-height: 150px; align-items: center;" min-height="150" max-height="200">
                        <div class="ma-4" style="width: 100%">
                          <p class="title font-weight-bold ma-0" :style="`color: ${affiliation.micrositeConfiguration.secondaryColor}`">
                            Texto
                          </p>
                        </div>
                        <div class="ma-4 d-flex justify-end" style="width: 100%">
                          <v-btn :color="affiliation.micrositeConfiguration.buttonColor" rounded :dark="affiliation.micrositeConfiguration.textButtonColor=='white'"><span class="font-weight-bold">Botón</span></v-btn>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>-->
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold">Configuración de colores</p>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-row>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Color primario"
                  filled
                  rounded
                  readonly
                  @click="primaryColorDialog = true"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      :color="affiliation.micrositeConfiguration.primaryColor"
                      large
                      @click="primaryColorDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                      >fas fa-square</v-icon
                    >
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" dark v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Laudantium distinctio quia neque, voluptatibus,
                        pariatur eum velit perspiciatis laboriosam veritatis,
                        exercitationem reprehenderi.</span
                      >
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  label="Color secundario"
                  filled
                  rounded
                  readonly
                  @click="secondaryColorDialog = true"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      :color="affiliation.micrositeConfiguration.secondaryColor"
                      large
                      @click="secondaryColorDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                      >fas fa-square</v-icon
                    >
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" dark v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Laudantium distinctio quia neque, voluptatibus,
                        pariatur eum velit perspiciatis laboriosam veritatis,
                        exercitationem reprehenderi.</span
                      >
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  label="Color botón"
                  filled
                  rounded
                  readonly
                  @click="buttonColorDialog = true"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      :color="affiliation.micrositeConfiguration.buttonColor"
                      large
                      @click="buttonColorDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                      >fas fa-square</v-icon
                    >
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" dark v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Laudantium distinctio quia neque, voluptatibus,
                        pariatur eum velit perspiciatis laboriosam veritatis,
                        exercitationem reprehenderi.</span
                      >
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pt-0">
                <v-switch
                  v-model="affiliation.micrositeConfiguration.textButtonColor"
                  class="mt-0"
                  color="black"
                  false-value="white"
                  true-value="black"
                  :label="`Texto botón ${
                    affiliation.micrositeConfiguration.textButtonColor ==
                    'black'
                      ? '(Negro)'
                      : '(Blanco)'
                  }`"
                  @change="_updateAffiliation()"
                >
                  <template v-slot:label>
                    {{
                      `Texto botón ${
                        affiliation.micrositeConfiguration.textButtonColor ==
                        "black"
                          ? "(Negro)"
                          : "(Blanco)"
                      }`
                    }}
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" class="ml-2" dark v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Lorem ipsum dolor sit amet consectetur adipisicing
                        elit. Laudantium distinctio quia neque, voluptatibus,
                        pariatur eum velit perspiciatis laboriosam veritatis,
                        exercitationem reprehenderi.</span
                      >
                    </v-tooltip>
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row
              class="py-5 mx-1"
              v-bind:style="`background-color: ${
                affiliation.micrositeConfiguration &&
                affiliation.micrositeConfiguration.primaryColor
                  ? affiliation.micrositeConfiguration.primaryColor
                  : '#f0f0f0'
              }; border-radius: 20px`"
            >
              <v-col cols="12" md="8" lg="9" class="pl-12">
                <p
                  class="mb-1 font-weight-black display-1"
                  :style="`color: ${affiliation.micrositeConfiguration.secondaryColor}`"
                >
                  {{ affiliation.name }}
                </p>
                <!--<p
                  class="mb-1 font-weight-medium subtitle-1"
                  :style="`color: ${affiliation.micrositeConfiguration.secondaryColor}`"
                >
                  {{affiliation.type}} / {{affiliation.category}}
                </p>-->
                <p
                  class="mb-1 font-weight-medium subtitle-1"
                  :style="`color: ${affiliation.micrositeConfiguration.secondaryColor}`"
                >
                  <v-icon
                    :color="affiliation.micrositeConfiguration.secondaryColor"
                  >
                    {{
                      affiliation.affiliationModality == "PRESENTIAL"
                        ? "mdi-google-maps"
                        : affiliation.affiliationModality == "VIRTUAL"
                        ? "mdi-earth"
                        : "mdi-merge"
                    }}
                  </v-icon>
                  {{ affiliationModalities[affiliation.affiliationModality] }}
                </p>
              </v-col>
              <v-col cols="12" md="4" lg="3" class="d-flex align-center">
                <v-row class="d-flex justify-end">
                  <!--<v-col cols="12" class="pa-0 d-flex justify-center">
                    <p
                      class="font-weight-bold subtitle-1"
                      :style="`color: ${affiliation.micrositeConfiguration.secondaryColor}`"
                    >
                      ¡Quedan pocos lugares!
                    </p>
                  </v-col>-->
                  <v-col cols="12" class="pa-0 d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="font-weight-bold"
                          :color="
                            affiliation.micrositeConfiguration.buttonColor
                          "
                          rounded
                          :dark="
                            affiliation.micrositeConfiguration
                              .textButtonColor == 'white'
                          "
                          v-bind="attrs"
                          v-on="on"
                          >Convertirme en miembro
                        </v-btn>
                      </template>
                      <span>Botón ilustrativo, no tiene funcionalidad</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold mb-0">
              Descripción de la membresía
            </p>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="affiliation.description"
                      :extensions="extensions"
                      :max-height="$vuetify.breakpoint.smAndUp ? '450' : '250'"
                      :toolbar-attributes="{
                        color: 'primary',
                        rounded: 't-lg',
                        dark: true,
                      }"
                      :card-props="{
                        elevation: '0',
                        rounded: 'lg',
                        color: '#f0f0f0',
                      }"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="font-weight-bold black--text"
                          color="secondary"
                          rounded
                          v-bind="attrs"
                          v-on="on"
                          @click="_updateAffiliation()"
                          :disabled="
                            !user.permissions.Membership ||
                            !user.permissions.Membership.update
                          "
                          >Guardar descripción</v-btn
                        >
                      </template>
                      <span>Guardar cambios de descripción de membresía</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold">Redes sociales</p>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-row v-if="affiliation.socialMedia">
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  v-model="affiliation.socialMedia.facebook"
                  class="mt-0"
                  label="Facebook"
                  filled
                  rounded
                  @blur="_updateAffiliation()"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  v-model="affiliation.socialMedia.instagram"
                  class="mt-0"
                  label="Instagram"
                  filled
                  rounded
                  @blur="_updateAffiliation()"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  v-model="affiliation.socialMedia.twitter"
                  class="mt-0"
                  label="Twitter"
                  filled
                  rounded
                  @blur="_updateAffiliation()"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pt-0">
                <v-text-field
                  v-model="affiliation.socialMedia.linkedin"
                  class="mt-0"
                  label="LinkedIn"
                  filled
                  rounded
                  @blur="_updateAffiliation()"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!--Patrocinadores-->
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold text-center">Patrocinadores</p>
          </v-col>
          <v-col
            v-if="affiliation.sponsors && affiliation.sponsors.length > 0"
            cols="12"
            class="d-flex justify-center py-0"
          >
            <v-row justify="center">
              <v-col
                v-for="sponsor in affiliation.sponsors"
                :key="sponsor._id"
                cols="6"
                sm="4"
                lg="3"
                xl="2"
                class="py-2 d-flex justify-center"
              >
                <v-avatar size="120" rounded="0">
                  <v-img
                    class="sponsor-img rounded-xl"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    :src="sponsor.logo"
                    width="auto"
                    contain
                  ></v-img>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        absolute
                        fab
                        top
                        right
                        color="red"
                        small
                        class="mt-6"
                        @click="openRemoveSponsorDialog(sponsor)"
                        v-bind="attrs"
                        v-on="on"
                        :disabled="
                          !user.permissions.Membership ||
                          !user.permissions.Membership.update
                        "
                      >
                        <v-icon small color="white"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Eliminar patrocinador</span>
                  </v-tooltip>
                </v-avatar>
              </v-col>
              <v-col
                cols="6"
                sm="4"
                lg="3"
                xl="2"
                class="py-2 d-flex justify-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="accent"
                      fab
                      x-large
                      @click="sponsorDialog = true"
                      :disabled="
                        !user.permissions.Membership ||
                        !user.permissions.Membership.update
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar patrocinador</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else cols="12" class="d-flex justify-center py-0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="accent"
                  fab
                  x-large
                  @click="sponsorDialog = true"
                  :disabled="
                    !user.permissions.Membership ||
                    !user.permissions.Membership.update
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Agregar patrocinador</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!--INICIO v-dialog para cargar banner-->
      <!-- <file-pond
        name="affiliation-banner"
        ref="pondBanner"
        label-idle="Agrega un banner para tu membresía..."
        accepted-file-types="image/jpeg, image/png"
      /> -->
    <image-cropping-dialog 
    :cropperDialog="bannerDialog" 
    :disableComponent="!user.permissions.Membership ||!user.permissions.Membership.update"
    @submitImages="uploadBanners"
    @close="bannerDialog=false"
    >
          <template v-slot:dialogTitle>
            Banner de membresía
          </template>
          <template v-slot:dialogSubtitle>
            Esta imagen se mostrará en la parte superior del micrositio.
            Para tener una visualización adecuada <span class="font-weight-bold">se recomienda que la
            imagen sea de 1500x500 pixeles</span>.
          </template>
    </image-cropping-dialog>
    <!--FIN v-dialog para cargar banner-->
    <!--INICIO v-dialog para cargar flyer-->
    <image-cropping-dialog
    :cropper-dialog="flyerDialog"
    :flyer="true"
    :disableComponent="!user.permissions.Membership ||!user.permissions.Membership.update"
    @submitImages="uploadFlyers"
    @close="flyerDialog=false">
    <template v-slot:dialogTitle>
            Flyer de membresía
          </template>
          <template v-slot:dialogSubtitle>
            Esta imagen se mostrará en el cuerpo del micrositio.
            Normalmente se usa para mostrar información de tu membresía de
                  una forma más gráfica. Para tener una visualización adecuada
                   <span class="font-weight-bold">se recomienda que la imagen sea de 800 pixeles de ancho y de
                  alto puede ser cualquier medida.</span>
                </template>
              </image-cropping-dialog>
    <!--FIN v-dialog para cargar flyer-->
    <!--INICIO v-dialog para cargar patrocinador-->
    <v-dialog v-model="sponsorDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Patrocinadores
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 text-justify py-0">
                <p>
                  Esta imagen se mostrará en el micrositio. Para tener una
                  visualización adecuada se recomienda que la imagen sea de
                  500x500 pixeles.
                </p>
              </v-col>
              <v-col cols="12">
                <file-pond
                  name="sponsor-image"
                  ref="sponsorImage"
                  label-idle="Agrega una imagen de patrocinador para tu membresía..."
                  accepted-file-types="image/jpeg, image/png"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-text-field
                  dense
                  filled
                  rounded
                  label="Link al sitio del patrocinador"
                  v-model="sponsorLink"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="(sponsorDialog = false), (sponsorLink = '')"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
                elevation="0"
                ><span class="font-weight-bold black--text">Cerrar</span></v-btn
              >
              <v-btn
                class="mx-2"
                color="accent"
                rounded
                elevation="0"
                @click="
                  uploadSponsor(), (sponsorDialog = false), (sponsorLink = '')
                "
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
                ><span class="font-weight-bold black--text"
                  >Guardar</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para cargar patrocinador-->
    <!--INICIO v-dialog para eliminar patrocinador-->
    <v-dialog v-model="removeSponsorDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Patrocinadores
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 py-0">
                <p>
                  ¿Está seguro que quiere eliminar la imagen del patrocinador
                  seleccionado?. La imagen no podrá ser recuperada a menos que
                  sea subida nuevamente.
                </p>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="removeSponsorDialog = false"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
                elevation="0"
                ><span class="font-weight-bold black--text">Cerrar</span></v-btn
              >
              <v-btn
                class="mx-2"
                color="red"
                rounded
                elevation="0"
                @click="removeSponsor()"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
                ><span class="font-weight-bold white--text"
                  >Remover</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog eliminar patrocinador-->
    <!--INICIO ESTE DIALOG SE VA A QUITAR-->
    <v-dialog v-model="descriptionDialog" max-width="750">
      <v-card>
        <p class="headline font-weight-medium px-7 pt-5">
          Descripción de membresía
        </p>
        <v-card-text class="d-flex justify-center pa-2">
          <tiptap-vuetify
            v-model="affiliation.description"
            :extensions="extensions"
            :max-height="$vuetify.breakpoint.smAndUp ? '450' : '250'"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            color="grey lighten-3"
            @click="cancelUpdateAffiliation(), (descriptionDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
          >
            Cancelar
          </v-btn>
          <v-btn
            color="accent"
            @click="_updateAffiliation(), (descriptionDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN ESTE DIALOG SE VA A QUITAR-->
    <!--INICIO v-dialog para seleccionar el color primario-->
    <v-dialog v-model="primaryColorDialog" max-width="400">
      <v-card>
        <p class="title font-weight-bold text-center px-7 pt-5">
          Selecciona el color primario
        </p>
        <v-card-text class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="affiliation.micrositeConfiguration.primaryColor"
            class="v-color-picker-style ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            class="font-weight-bold black--text"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="cancelUpdateAffiliation(), (primaryColorDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
            >Cancelar</v-btn
          >
          <v-btn
            class="font-weight-bold black--text"
            color="accent"
            rounded
            elevation="0"
            @click="_updateAffiliation(), (primaryColorDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para seleccionar el color primario-->
    <!--INICIO v-dialog para seleccionar el color secundario-->
    <v-dialog v-model="secondaryColorDialog" max-width="400">
      <v-card>
        <p class="title font-weight-bold text-center px-7 pt-5">
          Selecciona el color secundario
        </p>
        <div class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="affiliation.micrositeConfiguration.secondaryColor"
            class="v-color-picker-style ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </div>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            class="font-weight-bold black--text"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="cancelUpdateAffiliation(), (secondaryColorDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
            >Cancelar</v-btn
          >
          <v-btn
            class="font-weight-bold black--text"
            color="accent"
            rounded
            elevation="0"
            @click="_updateAffiliation(), (secondaryColorDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para seleccionar el color secundario-->
    <!--INICIO v-dialog para seleccionar el color del botón-->
    <v-dialog v-model="buttonColorDialog" max-width="400">
      <v-card>
        <p class="title font-weight-bold text-center px-7 pt-5">
          Selecciona el color del botón
        </p>
        <div class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="affiliation.micrositeConfiguration.buttonColor"
            class="v-color-picker-style ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </div>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            class="font-weight-bold black--text"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="cancelUpdateAffiliation(), (buttonColorDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
            >Cancelar</v-btn
          >
          <v-btn
            class="font-weight-bold black--text"
            color="accent"
            rounded
            elevation="0"
            @click="_updateAffiliation(), (buttonColorDialog = false)"
            :disabled="
              !user.permissions.Membership ||
              !user.permissions.Membership.update
            "
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para seleccionar el color del botón-->
    <!--INICIO v-dialog para cargar patrocinador-->
    <v-dialog v-model="newAffiliationMicrositeDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Configuración del micrositio
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 text-justify py-0">
                <p>
                  En este apartado podrás configurar todos los datos relevantes
                  sobre tu membresía. Por ejemplo, el nombre de la membresía,
                  colores principales del micrositio, redes sociales,
                  patrocinadores de tu membresía.
                </p>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="newAffiliationMicrositeDialog = false"
                :disabled="
                  !user.permissions.Membership ||
                  !user.permissions.Membership.update
                "
                elevation="0"
                ><span class="font-weight-bold black--text"
                  >Entendido</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para cargar patrocinador-->
  </v-container>
</template>
<script>
import { Base64 } from "js-base64";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import { imageHelper } from "@/utils/imageProcessing.js";
import { mapState, mapMutations, mapActions } from "vuex";

// Plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Filepond plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

//VueFilePond
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
const FilePond = vueFilePond(FilePondPluginFileValidateType);

import ImageCroppingDialog from "../../../components/shared/ImageCroppingDialog.vue";

export default {
  components: {
    TiptapVuetify,
    FilePond,
    ImageCroppingDialog
  },
  data() {
    return {
      affiliationModalities: {
        HYBRID: "Híbrido",
        PRESENTIAL: "Presencial",
        VIRTUAL: "Virtual",
      },
      image:{
        src: 'https://www.cdc.gov/healthypets/images/pets/cute-dog-headshot.jpg?_=42445',
        type: "image/jpg",
      },
      limitations: {
				minWidth: 100,
				minHeight: 50,
        maxHeight:100,
        maxWidth:100,
			},
      files: null,
      newAffiliationMicrositeDialog: false,
      bannerDialog: false,
      flyerDialog: false,
      sponsorDialog: false,
      removeSponsorDialog: false,
      descriptionDialog: false,
      primaryColorDialog: false,
      sponsorLink: "",
      secondaryColorDialog: false,
      buttonColorDialog: false,
      place: null,
      description: "",
      removeSponsorSelected: {},
      affiliation: {
        _id: "",
        published: false,
        banner: "",
        name: "",
        description: "",
        youtubeVideo: "",
        emailContact: "",
        micrositeConfiguration: {
          link: "",
          primaryColor: "",
          secondaryColor: "",
          buttonColor: "",
          textButtonColor: "white",
        },
        presentialAffiliationConfiguration: {
          name: "",
          city: "",
          country: "",
          formattedAddress: "",
          latitude: null,
          longitude: null,
        },
        socialMedia: {
          facebook: "",
          instagram: "",
          linkedin: "",
          twitter: "",
          youtubeChanel: "",
        },
      },
      // Copia del objeto de membresía. Se usa para guardar los datos de la membresía de forma temporal, y sirve para regresar los valores de una membresía en caso de que el usuario quiera cancelar alguna operación.
      affiliationTemp: {
        _id: "",
        published: false,
        banner: "",
        name: "",
        description: "",
        youtubeVideo: "",
        emailContact: "",
        micrositeConfiguration: {
          link: "",
          primaryColor: "",
          secondaryColor: "",
          buttonColor: "",
          textButtonColor: "white",
        },
        presentialAffiliationConfiguration: {
          name: "",
          city: "",
          country: "",
          formattedAddress: "",
          latitude: null,
          longitude: null,
        },
        virtualAffiliationConfiguration: {
          url: "",
          meetingId: "",
          meetingPassword: "",
        },
      },
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  computed: {
    ...mapState("affiliation", ["affiliationSelected"]),
    ...mapState("user", ["user"]),
  },
  methods: {
    ...mapActions("affiliation", [
      "fetchAffiliationById",
      "updateAffiliation",
      "addAffiliationSponsor",
      "removeAffiliationSponsor",
    ]),
    ...mapMutations("loader", ["loading", "loaded"]),
    async uploadBanners(bannersArray) {
      this.loading();
      for (const banner of bannersArray) {
        await this.uploadBanner(banner);
      }
      this.loaded();
    },
    async uploadBanner(file) {
      this.loading();
      let bannerTemp = await imageHelper.resizeImg(file, 1500, 500);
      this.affiliation.banner = await imageHelper.saveImage(bannerTemp);
      this.affiliationTemp = JSON.parse(JSON.stringify(this.affiliation));
      this.affiliationTemp.description = Base64.encode(
        this.affiliationTemp.description
      );
      await this.updateAffiliation(this.affiliationTemp);
      this.affiliationTemp = JSON.parse(JSON.stringify(this.affiliation));
      this.bannerDialog = false;
      this.loaded()
    },
    async fetchAffiliationInformation() {
      let auxAffiliation = JSON.parse(JSON.stringify(this.affiliation));
      await this.fetchAffiliationById(this.$route.params.affiliationId);
      // Convertir la descripción de base64 a texto normal
      this.affiliationSelected.description = Base64.decode(
        this.affiliationSelected.description
      );
      if (
        this.affiliationSelected.presentialAffiliationConfiguration &&
        this.affiliationSelected.presentialAffiliationConfiguration
          .formattedAddress
      ) {
        this.place =
          this.affiliationSelected.presentialAffiliationConfiguration.formattedAddress;
      }
      Object.assign(auxAffiliation, this.affiliationSelected);
      this.affiliation = JSON.parse(JSON.stringify(auxAffiliation));
      this.affiliationTemp = JSON.parse(
        JSON.stringify(this.affiliationSelected)
      );
    },
    cancelUpdateAffiliation() {
      this.affiliation = JSON.parse(JSON.stringify(this.affiliationTemp));
    },
    async _updateAffiliation() {
      try {
        this.loading();
        this.affiliationTemp = JSON.parse(JSON.stringify(this.affiliation));
        this.affiliationTemp.description = Base64.encode(
          this.affiliationTemp.description
        );
        let response = await this.updateAffiliation(this.affiliationTemp);
        this.affiliationTemp = JSON.parse(JSON.stringify(this.affiliation));
        // Cuando este en producción quitar el setTimeout
        setTimeout(() => {
          this.loaded();
        }, 500);
      } catch (error) {
        this.loaded();
        console.log(error.message);
      }
    },
    async uploadFlyers(flyersArray) {
      this.loading();
      for (const flyer of flyersArray) {
        await this.uploadFlyer(flyer);
      }
      this.loaded();
    },
    async uploadFlyer(file) {
      this.loading();
      // const file = this.$refs.pondFlyer.getFiles()[0].file;
      let flyerTemp = await imageHelper.resizeImg(file, 800, 2000);
      this.affiliation.flyer = await imageHelper.saveImage(flyerTemp);
      console.log(this.affiliation.flyer);
      this.affiliationTemp = JSON.parse(JSON.stringify(this.affiliation));
      this.affiliationTemp.description = Base64.encode(
        this.affiliationTemp.description
      );
      await this.updateAffiliation(this.affiliationTemp);
      this.affiliationTemp = JSON.parse(JSON.stringify(this.affiliation));
      this.flyerDialog = false;
      this.loaded()
    },
    async uploadSponsor() {
      this.loading();
      const link = this.sponsorLink;
      const file = this.$refs.sponsorImage.getFiles()[0].file;
      let sponsorTemp = await imageHelper.resizeImg(file, 400, 400);
      let sponsorImg = await imageHelper.saveSponsorImage(sponsorTemp);
      console.log(sponsorImg);
      let sponsorData = {
        affiliationId: this.$route.params.affiliationId,
        name: "",
        logo: sponsorImg,
        url: link,
      };
      await this.addAffiliationSponsor(sponsorData);
      this.sponsorDialog = false;
      this.$refs.sponsorImage.removeFiles();
      // Cuando este en producción quitar el setTimeout
      setTimeout(() => {
        this.loaded();
      }, 500);
      await this.fetchAffiliationInformation();
    },
    async removeSponsor() {
      this.loading();
      let sponsorData = {
        affiliationId: this.$route.params.affiliationId,
        sponsorId: this.removeSponsorSelected._id,
        logo: this.removeSponsorSelected.logo,
      };
      await this.removeAffiliationSponsor(sponsorData);
      await this.fetchAffiliationInformation();
      this.removeSponsorDialog = false;
      setTimeout(() => {
        this.loaded();
      }, 500);
    },
    /**
     * Función para borrar un patrocinador de la lista de patrocinadores
     */
    openRemoveSponsorDialog(sponsor) {
      this.removeSponsorSelected = sponsor;
      this.removeSponsorDialog = true;
    },
  },
  async mounted() {
    await this.fetchAffiliationInformation();
    if (!this.affiliationSelected.micrositeConfiguration) {
      this.newAffiliationMicrositeDialog = true;
    }
  },
};
</script>
<style scoped>
.bg-color {
  background-color: aquamarine;
}
.sponsor-img {
  filter: grayscale(1);
  height: auto;
  /*max-height: 100px;
  width: 100%;
  max-width: 150px;*/
  transition: 0.2s all ease-in-out;
  border-radius: 15px;
  justify-self: center;
}
.v-color-picker-style {
  background-color: #ffffff00;
}
.disabled {
  opacity: 0.5;
}
</style>